import React from "react";

import { Layout, SEO } from '../components/structure';
import { Button, Content, Hero } from "../components/blocks"
import styled from "styled-components";
import { brand, mq } from "../styles";
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const FourOhFourContainer = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 80px 80px;
  margin-bottom: -20px;
  
  > h4 {
    color: ${brand.colors.pg_primary_dark};
    font-size: 32px;
    max-width: 1400px;
    margin: auto;
  }
`;

const ActionContainer = styled.div`
  max-width: 1400px;
  margin: 40px auto;
  > span {
    margin-bottom: 15px;
  }

  > span:not(:last-child) {
    display: inline-block;
    margin-right: 15px;

    @media only screen and ${mq.maxSm} {
      display: block;
    }
  } 
`;

const NotFoundPage = (props) => {

  const pageQuery = useStaticQuery(graphql`
    query {
      fourohfour: file(base: { eq: "FourOhFourBG.jpg" }) {
       ...FullHeroBackground
      },
      rightArrowIcon: file(base: {eq: "arrow-right.png"}) {
        ...ButtonIcon
      }
    }
  `);

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Content>
        <Hero 
          title={'Error 404 -<br class="desktop-break"><span class="mobile-space"> </span>Not&nbsp;Found'}
          image = {pageQuery['fourohfour']}
          fullBG
        />
        <FourOhFourContainer>
          <h4>Oops! Looks like you've ventured off the beaten path. Don't worry - use the options below to get back on track.</h4>
          <ActionContainer>
            <Button destination={'/'} label={'Take me home'} icon={pageQuery['rightArrowIcon']} orange whiteText pill />
            <Button destination={'/contact/'} label={'Contact us'} icon={pageQuery['rightArrowIcon']} blue whiteText pill />
          </ActionContainer>
        </FourOhFourContainer>
      </Content>
    </Layout>
  );
}

export default NotFoundPage;
